import { useState, useEffect } from 'react';
import axios from 'axios';
import _ from 'lodash';
// @mui
import { Box, Grid, Typography, } from '@mui/material';


// MUI icons
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import BusinessIcon from '@mui/icons-material/Business';
import { RiRouterFill } from 'react-icons/ri';
// Custom components
import SectionHeader from '../../components/SectionHeader/SectionHeader.component';
import DeviceStatusCard from '../../components/Card/DeviceStatusCard.component';
import WelcomeCard from '../../components/Card/WelcomeCard.component';
// import DeviceStatusCard from '../../components/Card/DeviceStatusCard2.component';

const getValueByKey = (d, k) => !_.isEmpty(d) && _.has(d, k) ? d[k] : '-';


const OverviewPage = () => {
    const [data, setData] = useState(null);

    const getData = async () => {
        const response = await axios.get('/overview/counts');
        const result = await response.data;
        setData(result.data);
    }

    useEffect(() => {
        getData();
    }, []);



    return (
        <Box>
            <SectionHeader heading='Overview' />
            {/* <Box sx={{ m: 1, mt: 3, p: 1 }}>
                <Grid container direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={1}
                >
                </Grid>
                <Grid item xs={2} md={"auto"}>
                    <Typography variant="h6" component="div">
                        {"Device(s) Status"}
                    </Typography>
                </Grid>

            </Box> */}
            <Box sx={{ flexGrow: 1, my: 2 }}>
                <Grid container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start "
                    // alignItems="baseline"
                    spacing={2}
                >
                    <Grid item lg={6}>
                        <WelcomeCard />
                    </Grid>
                    <Grid item lg={6}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start" //"space-around"
                            alignItems="baseline"
                            spacing={2}
                        >
                            {/* <Grid item lg={8}>
                        <DeviceStatusCard icon={<CheckIcon />} bgcolor={{ bgcolor: '#28B25F' }} count={'2'} title={'Model'} />
                    </Grid> */}
                            <Grid item lg={6}>
                                <DeviceStatusCard
                                    icon={<LightbulbIcon sx={{ fontSize: 36 }} />}
                                    bgcolor={{ bgcolor: '#28B25F' }}
                                    count={getValueByKey(data, 'online')}
                                    title={'Online'}
                                />
                            </Grid>
                            <Grid item lg={6}>
                                <DeviceStatusCard
                                    icon={<RiRouterFill size='36px' />}
                                    bgcolor={{ bgcolor: '#A333C8' }}
                                    count={getValueByKey(data, 'totalDevices')}
                                    title={'All devices'}
                                />
                            </Grid>

                            <Grid item lg={6}>
                                <DeviceStatusCard
                                    icon={<ErrorIcon sx={{ fontSize: 36 }} />}
                                    bgcolor={{ bgcolor: '#DB445F' }}
                                    count={getValueByKey(data, 'offline')}
                                    title={'Offline'}
                                />
                            </Grid>
                            <Grid item lg={6}>
                                <DeviceStatusCard
                                    icon={<BusinessIcon sx={{ fontSize: 36 }} />}
                                    bgcolor={{ bgcolor: '#D9B03B' }}
                                    count={getValueByKey(data, 'childCompanies')}
                                    title={'Companies'}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ flexGrow: 1, my: 2 }}>
                <Grid container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start "
                    // alignItems="baseline"
                    spacing={2}
                >
                    <Grid item lg={8}>
                    </Grid>
                    <Grid item lg={4}>
                    </Grid>
                </Grid>
            </Box>
        </Box >
    );
}
export default OverviewPage;
