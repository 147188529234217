import {
    Box, Stack, Typography, Button, TextField
} from "@mui/material";
import { styled } from "@mui/material/styles";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
// Custom Component
import SectionHeader from "../../components/SectionHeader/SectionHeader.component";
const Item = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
const validationSchema = Yup.object().shape({
    email: Yup.string('Email must be valid').required("Email is required"),
});
export default function InviteUser() {
    const handleSubmit = (v) => {
        // console.log(JSON.stringify(v));
        // alert(JSON.stringify(v));
    }
    return (
        <Box>
            <SectionHeader heading="Invite User" />
            <Box sx={{ flexGrow: 1, my: 2 }}>
                <Formik
                    initialValues={{ email: '' }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                        <Form>
                            <Stack
                                direction="column"
                                justifyContent="center"
                                justifyItems="center"
                                spacing={2}
                            >
                                <Item>
                                    <Field
                                        as={TextField}
                                        autoFocus
                                        // fullWidth
                                        margin="normal"
                                        variant="standard"
                                        id="email"
                                        name="email"
                                        label="Email"
                                        value={values.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name && errors.name}
                                    />

                                </Item>
                                <Item>

                                    {/* <Button onClick={}>Cancel</Button> */}
                                    <Button variant="contained" type="submit" >Send Invitation</Button>
                                </Item>

                            </Stack>
                        </Form>
                    )}
                </Formik>

            </Box>
        </Box>
    );
}