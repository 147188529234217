import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import _ from 'lodash';
import axios from "axios";
import dayjs from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat';

import {
    Box, IconButton, Grid, Button, Snackbar, Alert,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// Material Icons
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import SyncIcon from '@mui/icons-material/Sync';
import DeleteIcon from "@mui/icons-material/Delete";

// Custom Components
import SectionHeader from "../../components/SectionHeader/SectionHeader.component";
import Search from "../../components/Search/Search.component";
import DataTable from "../../components/DataTable/DataTable.component";

function SearchQuery(queryString, data) {
    const query = queryString.toLowerCase();
    const filteredData = data.filter((d) => {
        const n = d.name.toLowerCase();
        return n.includes(query);
    }, data);
    return filteredData
}

const columns = [
    {
        name: 'Name',
        selector: row => row['name'],
        sortable: true
    },
    {
        name: 'Email',
        selector: row => row['email'],
        sortable: true
    },
    {
        name: 'Company',
        selector: row => row['companyName'],
        sortable: true
    },
    {
        name: 'Role',
        selector: row => row['roleName'],
        sortable: true
    }, {
        name: 'Created On',
        cell: row => '-'
    }
]

const actions = ({ handleQueryInput, getData }) => (
    <Grid container
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-end"
    >
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <IconButton aria-label="refresh" onClick={getData}>
                <SyncIcon />
            </IconButton>
        </Grid>
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <Search id="distributorSearch" handleChange={handleQueryInput} placeholder="Name..." />
        </Grid>
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <Button disabled variant="contained" size="small" sx={{ float: 'right' }} endIcon={<FilterAltIcon />}>Filter</Button>
        </Grid>
    </Grid>
);

const contextActions = deleteHandler => (
    <IconButton onClick={deleteHandler}>
        <DeleteIcon />
    </IconButton>
)

const Invite = ({ handleNavigate }) => (
    <Button
        variant="contained"
        size="small"
        startIcon={<AddRoundedIcon />}
        onClick={handleNavigate}
    >Invite</Button>
)
export default function UserList() {
    dayjs.extend(localizedFormat);
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [queryInput, setQueryInput] = useState("");
    const [toggleCleared, setToggleCleared] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);

    // Avoid a layout jump when reaching the last page with empty rows.

    const getData = async () => {
        const response = await axios.get('/users/getAllUsers');
        const result = await response.data;
        setData(result.data);
    }
    const handleNavigate = () => navigate('invite-user');
    const handleQueryInput = (event) => {
        let query = event.target.value.trim().toLowerCase();
        // console.log("query", query);
        setQueryInput(query);
    }
    const deleteAll = () => {
        const rows = selectedRows.map(r => r.serial);
        if (window.confirm(`Are you sure you want to delete?`)) {
            setToggleCleared(!toggleCleared);
            setData(_.differenceBy(data, rows, 'serial'));
        }
    }
    const handleChange = (s) => {
        setSelectedRows(s.selectedRows);
    }
    useEffect(() => {
        getData();
    }, []);

    return (
        <Box>
            {/* Sectoin Header */}
            <SectionHeader heading="Users" />

            <Box sx={{ flexGrow: 1, my: 2 }}>
                <DataTable
                    title={<Invite handleNavigate={handleNavigate} />}
                    columns={columns}
                    data={SearchQuery(queryInput, data)}
                    defaultSortField="serial"
                    defaultSortFieldId={1}
                    actions={actions({ handleNavigate, handleQueryInput, getData })}
                    contextActions={contextActions(deleteAll)}
                    onSelectedRowsChange={handleChange}
                    clearSelectedRows={toggleCleared}
                    progressPending={loading}
                />
            </Box>
        </Box>

    );
}