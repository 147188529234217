import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import _ from 'lodash';
import { useTheme } from "@mui/material/styles";
// MUI Components
import {
    Box, Table, TableContainer,
    TableHead, TableBody, TableFooter, TablePagination, TableRow,
    TableCell, IconButton, Grid, Button, Paper, Snackbar, Alert, Checkbox, Chip
} from "@mui/material";

import dayjs from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat';

// Material Icons
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from "@mui/icons-material/Delete";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import SyncIcon from '@mui/icons-material/Sync';
import { FaCircle } from "react-icons/fa";
// Custom Components
import SectionHeader from "../../components/SectionHeader/SectionHeader.component";
import Search from "../../components/Search/Search.component";
import DataTable from '../../components/DataTable/DataTable.component';
import NavigationComponent from "../../components/Common/NavigateComponent.component";
// import FormDialog from "../../components/Distributors/Dialog.component";
import axios from "axios";
// import { useFormik } from "formik";
// import schemaValidation from "./schemaValidation.validation";
// import devices from "../../data/devices";

const columns = [
    {
        name: 'Status',
        compact: true,
        selector: row => row['connectionStatus'],
        cell: row => (row['connectionStatus'] === "connected") ?
            <Chip icon={<FaCircle size="16px" />} label="Online" color="success" size="small" variant="outlined" />
            :
            <Chip icon={<FaCircle size="16px" />} label="Offline" color="error" size="small" variant="outlined" />
    },
    {
        name: 'Action',
        compact: true,
        selector: row => null,
        cell: row => <NavigationComponent id={row.serial} iconComponent={<InfoIcon />} />
    },
    {
        name: 'Name',
        selector: row => row['name'],
        sortable: true
    },
    {
        name: 'Serial',
        selector: row => row['serial'],
        sortable: true
    },
    {
        name: 'Model',
        selector: row => row['model'],
        sortable: true
    },
    {
        name: 'Connection Type',
        selector: row => row['connectionType'],
        sortable: true
    },
    {
        name: 'Firmware Version',
        selector: row => row['firmwareVersion'],
        sortable: true
    },
    {
        name: 'Created On',
        selector: row => row['createdAt'],
        sortable: true,
        cell: row => dayjs(row['createdAt']).format('lll')
    },
];

const actions = ({ handleNavigate, handleQueryInput, getData }) => (
    <Grid container
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-end"
    // spacing={1}
    >

        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <IconButton aria-label="refresh" onClick={getData}>
                <SyncIcon />
            </IconButton>
        </Grid>
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <Search id="distributorSearch" handleChange={handleQueryInput} placeholder="Name..." />
        </Grid>
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <Button disabled variant="contained" size="small" sx={{ float: 'right' }} endIcon={<FilterAltIcon />}>Filter</Button>
        </Grid>
    </Grid>
);

const contextActions = deleteHandler => (
    <IconButton onClick={deleteHandler}>
        <DeleteIcon />
    </IconButton>
);

function SearchQuery(queryString, data) {
    const query = queryString.toLowerCase();
    const filteredData = data.filter((d) => {
        const n = d.name.toLowerCase();
        const s = d.serial.toLowerCase();
        const m = d.model.toLowerCase();
        return n.includes(query) || s.includes(query) || m.includes(query);
    }, data);
    return filteredData
}

const AddDevice = ({ handleNavigate }) => (<Button
    variant="contained"
    size="small"
    startIcon={<AddRoundedIcon />}
    onClick={handleNavigate}
>Device</Button>)
export default function DeviceList(props) {
    dayjs.extend(localizedFormat);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [toggleCleared, setToggleCleared] = useState(false);
    const [message, setMessage] = useState(false);
    const [queryInput, setQueryInput] = useState("");
    const [data, setData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    // const formik = useFormik({
    //     initialValues: {
    //         name: 'Heller'
    //     },
    //     validationSchema: schemaValidation,
    //     onSubmit: (v) => {
    //         console.log(v);
    //     }
    // })
    const deleteAll = () => {
        const rows = selectedRows.map(r => r.serial);
        if (window.confirm(`Are you sure you want to delete?`)) {
            setToggleCleared(!toggleCleared);
            setData(_.differenceBy(data, rows, 'serial'));
        }
    }
    const handleChange = (s) => {
        setSelectedRows(s.selectedRows);
    }
    const handleNavigate = () => navigate('add-devices');
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSnakbarClose = () => {
        setMessage(false);
    }

    const handleQueryInput = (event) => {
        let query = event.target.value.trim().toLowerCase();
        setQueryInput(query);
    }

    const getData = async () => {
        setLoading(true);
        // const response = await axios.get('http://localhost:5001/devices/getalldevices');
        const response = await axios.get('/devices/getalldevices');
        const data = await response.data;
        setData(data.data);
        // if(response.status===200)  
        setLoading(false);
    }

    useEffect(() => {
        getData();
        // setRows(devices.data);
    }, []);



    return (
        <Box>
            {/* Sectoin Header */}
            <SectionHeader heading="Devices" />

            <Box sx={{ flexGrow: 1, my: 2 }}>
                <DataTable
                    title={<AddDevice handleNavigate={handleNavigate} />}
                    columns={columns}
                    data={SearchQuery(queryInput, data)}
                    defaultSortField="serial"
                    defaultSortFieldId={1}
                    actions={actions({ handleNavigate, handleQueryInput, getData })}
                    contextActions={contextActions(deleteAll)}
                    onSelectedRowsChange={handleChange}
                    clearSelectedRows={toggleCleared}
                    progressPending={loading}
                />
            </Box>
            {/* <FormDialog
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
                open={open}
                getData={getData}
                setMessage={setMessage}
            /> */}
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={message}
                onClose={handleSnakbarClose}
                autoHideDuration={4000}
            >
                <Alert severity="success" onClose={handleSnakbarClose}>
                    {
                        "Added successfully"
                    }
                </Alert>
            </Snackbar>
        </Box >
    );
}