import { Routes, Route } from 'react-router-dom'
import UserList from './Users.main.page';
import InviteUser from '../Forms/InviteUsers.page';
const UserPage = () => {
    return (
        <Routes>
            <Route path='/' element={<UserList />} />
            <Route path='/invite-user' element={<InviteUser />} />
        </Routes>
    );
}

export default UserPage;