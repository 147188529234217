import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';

const NavigationComponent = ({ id, iconComponent }) => {
    const navigate = useNavigate();
    return (
        <IconButton onClick={() => navigate(id)}>
            {iconComponent}
        </IconButton>
    );
}
export default NavigationComponent;
