import { createAction } from "@reduxjs/toolkit";


export const signupRequest = createAction('signup/request');
export const signupSuccess = createAction('signup/success');
export const signupFailure = createAction('signup/failure');
export const verifyCodeRequest = createAction('verifyCode/request');
export const verifyCodeSuccess = createAction('verifyCode/success');
export const verifyCodeFailure = createAction('verifyCode/failure');
export const signupReset = createAction('signup/reset');

