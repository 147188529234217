import _ from "lodash";
import Avatar from "@mui/material/Avatar";
import { stringToColor } from "../../helper/Utility.helper";
const stringAvatar = (name) => {
    const size = 36;
    return {
        sx: {
            bgcolor: stringToColor(name),
            width: size,
            height: size
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        variant: 'circular'
    }
}

const AccountAvatar = ({ user }) => {
    const name = _.isEmpty(user) ? "Zombie" : user.idToken.payload.name;
    return <Avatar {...stringAvatar(name)} />;
}
export default AccountAvatar;
