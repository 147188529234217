import * as React from "react";
// MUI Components
import { Box, Typography, Breadcrumbs, Link, Divider } from "@mui/material";
import { useLocation, Link as RouterLink } from "react-router-dom";


// Event function for "Breadcrumbs";
const handleClick = event => {
    event.preventDefault();
    // console.log("You clicked a breadcrumb.");
}

// Section Header a resuable component
const SectionHeader = (props) => {

    let location = useLocation();
    let pathnames = location.pathname.split("/").filter((x) => x);

    return (

        <Box component="div">
            {/* A section name */}
            < Typography variant='h4' >
                {
                    props.heading ? props.heading :
                        'Section Heading'
                }
            </Typography >

            <Box component="div">
                {
                    pathnames.length != 1 ?
                        // false ?
                        <div role="presentation" onClick={handleClick}>
                            <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
                                {
                                    pathnames.map((p, i) => {
                                        const last = i === pathnames.length - 1
                                        const to = `/${pathnames.slice(0, i + 1).join('/')}`
                                        return last ?
                                            <Typography color="text.primary" key={p}>{p.toUpperCase()}</Typography>
                                            :
                                            <Link component={RouterLink} underline="hover" color="inherit" to={to} key={p} >
                                                {p.toUpperCase()}
                                            </Link>
                                    })
                                }

                            </Breadcrumbs>
                        </div> : null
                }
                <Divider />
            </Box>
        </Box >
    );
};

export default SectionHeader;