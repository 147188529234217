// @mui
import { Stack, Typography, Avatar, Paper } from "@mui/material";

import { BoxItem } from '../Common/Item.component';

const DeviceStatusCard = ({ icon, bgcolor, count, title }) => (
    <Paper elevation={4} sx={{ p: 2 }}>
        <Stack
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            spacing={1}
        >
            <BoxItem>
                <Typography variant="h2">{count}</Typography>
                <Typography variant="body1">{title}</Typography>
            </BoxItem>
            <BoxItem>
                <Avatar sx={{ ...bgcolor, width: '3em', height: '3em' }}>
                    {icon}
                </Avatar>
            </BoxItem>
        </Stack>
    </Paper>
);

export default DeviceStatusCard;