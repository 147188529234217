import { useState, useEffect } from "react";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Link,
} from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

export default function LinkDialog({ openLinkDialog, data, handleCloseLinkDialog }) {
    dayjs.extend(relativeTime)

    return (
        <div>
            {/* <Button variant="outlined" onClick={handleOpenLinkDialog}>
                Open alert dialog
            </Button> */}
            <Dialog
                open={openLinkDialog}
                onClose={handleCloseLinkDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Quick connect"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {"Open the "}
                        {
                            openLinkDialog && data ?
                                <Link href={data.link} underline="always" target="_blank">
                                    link
                                </Link>
                                : null
                        }
                        {" to access device. The link will expire in "}{(openLinkDialog && data) ? dayjs(data.expiration).toNow(true) : null}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseLinkDialog}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}