import { useEffect, useState } from "react";
import axios from "axios";
// import _ from 'lodash';
import { useFormik } from "formik";
import * as Yup from 'yup';
import {
    Box, Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle, Button, MenuItem, TextField,
} from "@mui/material";
// import { map } from "lodash";

export default function AddTunnel({ open,
    handleClose, setSnackbarOpen,
    setMessage,
    handleSnakbarClose }) {

    const [deviceList, setDeviceList] = useState([]);


    const getData = async () => {
        const response = await axios.get('/devices/deviceListType2');
        const result = await response.data;
        setDeviceList(result.data);
    }
    const postData = async (data) => {
        const response = await axios.post('/tunnels', data);
        const result = await response.data;
        handleSnakbarClose();
        setSnackbarOpen(true);
        setMessage(result);
        if (result.status) {
            handleClose();
            return;
        }
        return;
    }

    const formik = useFormik({
        initialValues: {
            name: '',
            deviceId: '',
            serviceTypeCode: '1',
            ipv4: 'localhost',
            port: '80',
        },
        validationSchema: Yup.object({
            name: Yup.string().required('tunnel name is required'),
            deviceId: Yup.string().required('select device'),
            serviceTypeCode: Yup.string().required('select service type'),
            ipv4: Yup.string().when('mode', {
                is: '1',
                then: Yup.string().required('ipv4 address is required'),
            }),
            port: Yup.string().when('mode', {
                is: '1',
                then: Yup.string().required('port is required'),
            }),
        }),
        onSubmit: (data) => {
            // alert(JSON.stringify(data, null, 2));
            postData(data);
        }
    })

    useEffect(() => {
        if (open) {
            getData();
        }
        return () => formik.resetForm();
    }, [open]);

    return (
        <Box>
            <Dialog
                // fullWidth
                maxWidth="sm"
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Create New Remote Connection</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Create a tunnel to configure the device remotely. Currently only 'http' type tunnel is supported, team of Atreyo is working on additional features to be implemented soon.
                    </DialogContentText>
                    <Box padding={1}>
                        <TextField
                            fullWidth
                            size="small"
                            label="Name"
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.errors.name && formik.touched.name}
                            helperText={formik.errors.name}
                        />
                    </Box>
                    <Box
                        display="flex"
                    // flexDirection="column"
                    >
                        <Box flexGrow={1} padding={1}>
                            <TextField
                                size="small"
                                fullWidth
                                select
                                label="Select Device"
                                name="deviceId"
                                value={formik.values.deviceId}
                                onChange={formik.handleChange}
                                error={formik.touched.deviceId && Boolean(formik.errors.deviceId)}
                                helperText={formik.touched.deviceId && formik.errors.deviceId}
                            >
                                {
                                    deviceList.map(d => (

                                        <MenuItem key={d._docId} value={d._docId}>{d.name}</MenuItem>
                                    ))
                                }
                            </TextField>
                        </Box>
                        <Box flexGrow={1} padding={1}>
                            <TextField
                                size="small"
                                fullWidth
                                select
                                label="Service Type"
                                name="serviceTypeCode"
                                value={formik.values.serviceTypeCode}
                                onChange={formik.handleChange}
                                error={formik.errors.serviceTypeCode && formik.touched.serviceTypeCode}
                                helperText={formik.errors.serviceTypeCode}
                            >
                                <MenuItem key="1" value="1">HTTP</MenuItem>
                                {/* <MenuItem key="2" value="2">HTTPS</MenuItem> */}
                                {/* <MenuItem key="3" value="3">SSH</MenuItem> */}
                            </TextField>
                        </Box>
                    </Box>
                    {formik.values.serviceTypeCode === '1' && (
                        <Box
                            display="flex"
                        >
                            <Box flexGrow={1} padding={1}>
                                <TextField
                                    size="small"
                                    label="Ipv4"
                                    name="ipv4"
                                    fullWidth
                                    value={formik.values.ipv4}
                                    onChange={formik.handleChange}
                                    error={formik.errors.ipv4 && formik.touched.ipv4}
                                    helperText={formik.errors.ipv4}
                                />
                            </Box>
                            <Box flexGrow={1} padding={1}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Port"
                                    name="port"
                                    value={formik.values.port}
                                    onChange={formik.handleChange}
                                    error={formik.errors.port && formik.touched.port}
                                    helperText={formik.errors.port}
                                />
                            </Box>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                    <Button onClick={formik.handleSubmit}>Add</Button>
                </DialogActions>
            </Dialog>
        </Box >
    );
}




