// Apex Char component
import Chart from "react-apexcharts";
// @mui
import { Box, Card, CardHeader, CardContent } from '@mui/material';
const ChartCard = (props) => (
    <Box sx={{ p: 2 }}>
        <Card >
            <CardHeader title={props.title} />
            <CardContent sx={{ p: 0, m: 0 }}>
                {props.children}
            </CardContent>
        </Card>
    </Box >
);

export default ChartCard;