import { useState, useEffect } from "react";
import _ from 'lodash';
import { useNavigate } from "react-router-dom";
import axios from "axios";
// MUI Components
import {
    Box, IconButton, Grid, Button,
} from '@mui/material';
// Material Icons
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from '@mui/icons-material/Add';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import DeleteIcon from "@mui/icons-material/Delete";

// Components
import SectionHeader from "../../components/SectionHeader/SectionHeader.component";
import Search from "../../components/Search/Search.component";
import NavigationComponent from "../../components/Common/NavigateComponent.component";
import DataTable from "../../components/DataTable/DataTable.component";

const columns = [
    {
        name: 'Role',
        selector: row => row['name'],
        sortable: true,
    },
    {
        name: 'Actions',
        selector: row => row['_docId'],
        sortable: false,
        cell: row => <NavigationComponent id={row['_docId']} iconComponent={<ArticleRoundedIcon />} />
    }
];

const actions = ({ handleQueryInput, getData }) => (
    <Grid
        container
        direction='row'
        justifyContent='flex-end'
        alignContent='center'

    >
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <IconButton aria-label="refresh" onClick={getData}>
                <SyncIcon />
            </IconButton>
        </Grid>
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <Search id='search-roles' handleChange={handleQueryInput} placeholder='Search role...' />
        </Grid>
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <Button disabled variant="contained" size="small" sx={{ float: 'right' }} endIcon={<FilterAltIcon />}>Filter</Button>
        </Grid>
    </Grid>
);
const contextActions = deleteHandler => (
    <IconButton onClick={deleteHandler}>
        <DeleteIcon />
    </IconButton>
)

function SearchQuery(queryString, data) {
    const query = queryString.toLowerCase();
    const filteredData = data.filter((d) => {
        const n = d.name.toLowerCase();
        return n.includes(query);
    }, data);
    return filteredData
}
const CreateRole = ({ handleNavigate }) => (
    <Button
        variant="contained"
        size="small"
        startIcon={<AddIcon />}
        onClick={handleNavigate}
    >Create Role</Button>
)
const RoleList = () => {
    const navigate = useNavigate();
    const [queryInput, setQueryInput] = useState("");
    const [loading, setLoading] = useState(false);
    const [toggleCleared, setToggleCleared] = useState(false);
    const [data, setData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    // const [error, setError] = useState(null);


    const handleNavigate = () => navigate('createRole');
    const deleteAll = () => {
        const rows = selectedRows.map(r => r.name);
        if (window.confirm(`Are you sure you want to delete?`)) {
            setToggleCleared(!toggleCleared);
            setData(_.differenceBy(data, rows, 'name'));
        }
    }
    const handleChange = (s) => {
        setSelectedRows(s.selectedRows);
    }
    const handleQueryInput = (event) => {
        const query = event.target.value.trim().toLowerCase();
        setQueryInput(query);
    }
    const getData = async () => {
        try {
            const response = await axios.get("/roles");
            const result = await response.data;
            if (result.status) {
                setData(result.data);
            }
        } catch (error) {
            console.log("ROLE ERROR", error);
        }
    }

    useEffect(() => {
        getData();
        return () => {
            setData([]);
        }
    }, []);

    return (
        <Box>
            {/* Sectoin Header */}
            <SectionHeader heading="Roles" />
            <Box sx={{ flexGrow: 1, my: 2 }}>
                <DataTable
                    title={<CreateRole handleNavigate={handleNavigate} />}
                    columns={columns}
                    data={SearchQuery(queryInput, data)}
                    defaultSortField="name"
                    defaultSortFieldId={1}
                    actions={actions({ handleQueryInput, getData })}
                    contextActions={contextActions(deleteAll)}
                    onSelectedRowsChange={handleChange}
                    clearSelectedRows={toggleCleared}
                    progressPending={loading}
                />
            </Box>
        </Box>
    );
}

export default RoleList;