import dayjs from "dayjs";
import _ from 'lodash';
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
// import convert from 'convert-units';

export const setDefaultTimezone = () => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    localStorage.setItem("timezone", dayjs.tz.guess());
}

export const getDefaultTimezone = () => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    return dayjs.tz.guess();
}

export const getTimezone = () => {
    return localStorage.getItem("timezone");
}

export const setTimeZone = (t) => {
    localStorage.setItem("timezone", t);
}

export const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export const getConvertedSpeed = (s) => {
    if (s.length === 0) {
        return '-';
    }
    if (parseInt(s) === 0) {
        return '0 B/s'
    }
    if (s.length <= 3) {
        return `${s} B/s`;
    }
    if (s.length > 3 && s.length <= 6) {
        const r = _.round((parseInt(s) / Math.pow(10, 3)), 2);
        return `${r} KB/s`;
    }
    if (s.length > 6 && s.length <= 9) {
        const r = _.round((parseInt(s) / Math.pow(10, 6)), 2);
        return `${r} MB/s`;
    }
    if (s.length > 9 && s.length <= 12) {
        const r = _.round((parseInt(s) / Math.pow(10, 9)), 2);
        return `${r} GB/s`;
    }
    if (s.length > 12) {
        const r = _.round((parseInt(s) / Math.pow(10, 12)), 2);
        return `${r} TB/s`;
    }

}
