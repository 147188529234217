import { useEffect, useState } from "react";
import _ from "lodash";
import { Box, Typography, Button, Grid, Snackbar, Alert, Popover } from '@mui/material';


// Custom Components
import SectionHeader from '../../components/SectionHeader/SectionHeader.component';
import AddTunnel from "../Forms/AddTunnel2.component";
import InfoCard from "../../components/Tunnel/InfoCard.component";
import LinkDialog from "../../components/Tunnel/LinkDialog.component";
// MUI icons
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import axios from "axios";


export default function WebConnect() {
    const [open, setOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [message, setMessage] = useState(null);
    const [data, setData] = useState([]);
    const [linkData, setLinkData] = useState(null);
    const [openLinkDialog, setOpenLinkDialog] = useState(false);


    const handleCloseLinkDialog = () => {
        setOpenLinkDialog(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }
    const handleSnakbarClose = () => setSnackbarOpen(false);

    const getData = async () => {
        const response = await axios.get('/tunnels');
        const result = await response.data;
        setData(result.data);
    }
    const deleteData = async (id) => {
        const response = await axios.delete(`/tunnels/${id}`);
        const result = await response.data;
        setMessage(result);
        setSnackbarOpen(true);
    }

    const generateTunnel = async (tunnelId, deviceId) => {
        const data = {
            "deviceId": deviceId,
            "tunnelId": tunnelId
        }
        const response = await axios.post('/tunnels/generatelink', data);
        const result = await response.data;
        // console.log(result);
        if (result.status) {
            setOpenLinkDialog(true);
            setLinkData(result.message);
            setTimeout(() => getData(), 1000 * 4);
            return;
        }
        setMessage(result);
        setSnackbarOpen(true);

        return;
    }

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (!_.isEmpty(message) && message.status) getData();
    }, [message]);



    return (
        <Box>
            <SectionHeader heading="Remote" />
            <Box sx={{ flexGrow: 1, my: 2 }}>
                <Grid container
                    direction="row"
                    justifyContent="space-between"
                    alignItem="flex-start"
                    spacing={2}
                >
                    <Grid item xs={12} md={"auto"}>
                        <Button
                            variant="contained"
                            size="small"
                            startIcon={<AddRoundedIcon />}
                            onClick={handleClickOpen}
                        >Add</Button>
                    </Grid>
                </Grid>
            </Box>

            <Box sx={{ mt: "20px" }}>
                <Grid container
                    spacing={2}
                >
                    {
                        (data.length > 0) ? data.map(tunnel => {
                            return (
                                <Grid item lg={3} xl={3} key={tunnel['_docId']}>
                                    <InfoCard
                                        key={tunnel['_docId']}
                                        tunnel={tunnel}
                                        generateTunnel={generateTunnel}
                                        onDelete={deleteData}
                                    />
                                </Grid>
                            )
                        }) :
                            <Grid item lg={12} xl={12}>
                                <Box display='flex' direction='column' justifyContent='center' alignItems='center'>
                                    <Typography variant="subtitle1" sx={{ color: '#707070' }}>No data found</Typography>
                                </Box>
                            </Grid>
                    }
                </Grid>
            </Box>

            <AddTunnel open={open}
                handleClose={handleClose}
                setSnackbarOpen={setSnackbarOpen}
                setMessage={setMessage}
                handleSnakbarClose={handleSnakbarClose} />
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={snackbarOpen}
                onClose={handleSnakbarClose}
                autoHideDuration={4000}
            >
                {
                    snackbarOpen ?
                        <Alert severity={(message['status']) ? "success" : "error"} onClose={handleSnakbarClose}>
                            {
                                message['message']
                            }
                        </Alert>
                        : null
                }
            </Snackbar>

            <LinkDialog openLinkDialog={openLinkDialog} handleCloseLinkDialog={handleCloseLinkDialog} data={linkData} />
        </Box>
    );
}