import { useState } from "react";
import _ from 'lodash';
import dayjs from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat';
import axios from 'axios';
import {
    Avatar, Box, Card, CardHeader, CardActions, CardContent, Typography, Button, Link, IconButton, Grid,
    Menu, MenuItem, ListItemText, ListItemIcon, MenuList, Popover
} from '@mui/material';
import { lightGreen } from '@mui/material/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CircleIcon from '@mui/icons-material/Circle';
import LaunchIcon from '@mui/icons-material/Launch';

export default function InfoCard({
    tunnel,
    generateTunnel,
    onDelete,
}) {
    dayjs.extend(localizedFormat);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElForPopover, setAnchorElForPopover] = useState(null);

    // Boolean Conversion from state
    const open = Boolean(anchorEl);
    const isPopOverOpen = Boolean(anchorElForPopover);

    // Handle function 
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleDeleteClose = () => {
        onDelete(tunnel._docId);
        setAnchorEl(null);
    }
    const handlePopoverOpen = (event) => setAnchorElForPopover(event.currentTarget);
    const handlePopoverClose = () => setAnchorElForPopover(null);
    const openPopupWindow = () => {
        const popup = window.open(`http://${tunnel.wildcard}.testlink.atreyo.io`, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes');
        console.log('popup', popup);
    }
    return (
        <Card sx={{ maxWidth: 500 }}>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: lightGreen[500], height: 36, width: 50, fontSize: 16 }} variant="rounded" aria-label="tunnel-type">
                        {tunnel.serviceType.toUpperCase()}
                    </Avatar>
                }
                action={
                    <>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={() => setAnchorEl(null)}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem dense onClick={() => setAnchorEl(null)}>Edit</MenuItem>
                            <MenuItem dense sx={{ color: '#DB445F' }} onClick={handleDeleteClose}>Delete</MenuItem>
                        </Menu>
                        <IconButton aria-label="tunnel-settings" size="small" onClick={handleClick}
                        >
                            <MoreVertIcon fontSize="inherit" />
                        </IconButton>
                    </>
                }
                title={tunnel.name}
                subheader={dayjs(tunnel.meta.createdAt).format('lll')}
            />
            <CardContent>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle2" component="div">
                            Device Name
                        </Typography>
                        <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" gap={"5px"}>
                            <Link
                                href={`/devices/${tunnel.deviceSerial}`}
                                target="_blank"
                                underline="hover"
                                variant="body2"
                                sx={{ cursor: 'pointer' }}
                            >
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    gap={"2px"}
                                >
                                    <Box>{tunnel.deviceName}</Box>
                                    <LaunchIcon sx={{ fontSize: 12, }} />
                                </Box>
                            </Link>
                            <Box sx={{ mb: 0.5, }}>
                                <CircleIcon
                                    aria-owns={isPopOverOpen ? 'mouse-over-popover' : undefined}
                                    aria-haspopup="true"
                                    onMouseEnter={handlePopoverOpen}
                                    onMouseLeave={handlePopoverClose}
                                    sx={{
                                        fontSize: 12,
                                        color: (tunnel.connectionStatus === "connected") ? '#28B25F' : '#DB445F'
                                    }}
                                />
                                <Popover
                                    id="connection-status-popover"
                                    sx={{
                                        pointerEvents: 'none',
                                    }}
                                    open={isPopOverOpen}
                                    anchorEl={anchorElForPopover}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    onClose={handlePopoverClose}
                                    disableRestoreFocus
                                >
                                    <Typography
                                        variant="caption"
                                        // display="block"

                                        sx={{
                                            p: 1,
                                            bgcolor: '#134E5E',
                                            color: '#ECEFF1',
                                            // bgcolor: '#ECEFF1',
                                            // color: '#134E5E',
                                        }}
                                        gutterBottom
                                    >{(tunnel.connectionStatus === "connected") ? 'Online' : 'Offline'}</Typography>
                                </Popover>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle2" component="div">
                            Model
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {tunnel.deviceModel}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle2" component="div">
                            IP
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {tunnel.localHostAddress}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle2" component="div">
                            Port
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {tunnel.localHostPort}
                        </Typography>
                    </Grid>
                    {
                        (tunnel.isActive === 'pending') ?
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle2" component="div">
                                    Tunnel Status
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {'Pending'}
                                </Typography>
                            </Grid> : null
                    }
                    {
                        (!_.isNil(tunnel.expirationTime)) ?
                            <Grid item xs={12} md={6}>
                                <Typography variant="subtitle2" component="div">
                                    Expiry Time
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {dayjs(tunnel.expirationTime).format('DD-MM-YYYY H:mm:ss')}
                                </Typography>
                            </Grid> : null
                    }

                </Grid>
                {
                    (tunnel.isActive === 'created') ?
                        <Button variant="contained" sx={{ mt: 2 }} size="small" onClick={() => generateTunnel(tunnel['_docId'], tunnel['deviceId'])}>
                            Generate Link
                        </Button> : null
                }
                {
                    (tunnel.isActive === 'live' && dayjs().isBefore(dayjs(tunnel.expirationTime))) ?
                        <Button variant="contained" sx={{ mt: 2 }} size="small" onClick={openPopupWindow}>
                            Open
                        </Button>
                        :
                        null
                }
                {
                    (tunnel.isActive === 'live' && dayjs().isAfter(dayjs(tunnel.expirationTime))) ?
                        <Button variant="contained" sx={{ mt: 2 }} size="small" onClick={() => generateTunnel(tunnel['_docId'], tunnel['deviceId'])}>
                            Re-generate Link
                        </Button> : null

                }
            </CardContent>
        </Card >
    );
}
