const AreaChartOptions = {
    chart: {
        id: 'area-datetime',
        type: 'area',
        group: 'metric',
        height: 250,
        zoom: {
            autoScaleYaxis: true
        }
    },

    dataLabels: {
        enabled: false
    },
    markers: {
        size: 0,
        style: 'hollow',
    },

    stroke: {
        curve: 'smooth',
    },

    tooltip: {
        x: {
            format: 'dd MMM yyyy'
        }
    },

    fill: {
        type: 'gradient',
        gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100]
        }
    },
    noData: {
        text: "No Data present in the graph!",
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
            color: "#000000",
            fontSize: '14px',
            fontFamily: "Helvetica"
        }
    },
    xaxis: {
        type: 'datetime',
        // min: new Date('01 Mar 2012').getTime(),
        // tickAmount: 6,
    },
};

const CPUChartOptions = {
    options: {
        ...AreaChartOptions,
        colors: ['#F4B400', '#F2711C']
    },
    series: []
}
const CPUChartOptionsLoading = {
    ...AreaChartOptions,
    colors: ['#F4B400', '#F2711C'],
    noData: {
        text: "Loading...",
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
            color: "#000000",
            fontSize: '14px',
            fontFamily: "Helvetica"
        }
    }

}
const CPUChartOptionsNoData = {
    ...AreaChartOptions,
    colors: ['#F4B400', '#F2711C'],
    noData: {
        text: "No Data present in the graph!",
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
            color: "#000000",
            fontSize: '14px',
            fontFamily: "Helvetica"
        }
    }

}

const RAMChartOptions = {
    options: {
        ...AreaChartOptions,
        colors: ['#F2711C']
    },
    series: []
}

const STORAGEChartOptions = {
    options: {
        ...AreaChartOptions,
        colors: ['#B388FF']
    },
    series: []
}

const TEMPERATUREChartOptions = {
    options: {
        ...AreaChartOptions,
        colors: ['#038C7F']
    },
    series: []
}

const NoDataOptions = {
    text: "No Data present in the graph!",
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
        color: "#000000",
        fontSize: '14px',
        fontFamily: "Helvetica"
    }
}

export default AreaChartOptions;
export {
    NoDataOptions,
    CPUChartOptions,
    CPUChartOptionsLoading,
    CPUChartOptionsNoData,
    RAMChartOptions,
    STORAGEChartOptions,
    TEMPERATUREChartOptions,
}