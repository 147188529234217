// import react from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { createTheme } from "@mui/material";
import _ from "lodash";
// import { useSelector } from "react-redux";

// Components
import MainDashboard from "./pages/Dashboard/Main.page";
import SignUp from "./pages/Forms/SignUp.page";
import LoginForm from "./pages/Forms/Login.page";
import indianOcean from "./assets/main.theme";
import { ThemeProvider } from "@emotion/react";
import { useSelector } from "react-redux";
const theme = createTheme(indianOcean);

function App() {
  const isAuthenticated = useSelector(state => state.authenticate.isAuthenticated);
  const currentLocation = useLocation();
  const from = _.get(currentLocation, "state.from.pathname", "/");

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Routes>
          <Route
            path="/signup"
            element={isAuthenticated ? <Navigate to="/" replace /> : <SignUp />}
          />
          <Route
            path="/*"
            element={isAuthenticated ? <MainDashboard /> : <Navigate to="/login" replace />}
          />
          <Route
            path="/login"
            element={!isAuthenticated ? <LoginForm /> : <Navigate to={from} replace />}
          />
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
