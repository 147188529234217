import { useEffect, useState } from "react";
import _, { result } from 'lodash';
import axios from "axios";
import {
    Box, IconButton, Grid, Button, Snackbar, Alert,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { useNavigate } from "react-router-dom";
// Material Icons
// import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from "@mui/icons-material/Delete";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import SyncIcon from '@mui/icons-material/Sync';

// Custom Components
import SectionHeader from "../../components/SectionHeader/SectionHeader.component";
import DataTable from "../../components/DataTable/DataTable.component";
import Search from "../../components/Search/Search.component";
import CreateCompany from "../Forms/CreateCompany.component";

const columns = [
    {
        name: 'Name',
        selector: row => row['name'],
        sortable: true,
    },
    {
        name: 'Parent Company',
        selector: row => (row['level'] === '2') ? row['rootCompanyName'] : row['parentCompanyName'],
        sortable: true
    },
    {
        name: 'Device Count',
        selector: row => row['deviceCount'],
    },
    {
        name: 'Created At',
        selector: row => row['meta']['createdAt'],
        sortable: true,
        cell: row => dayjs(row['meta']['createdAt']).format('lll')
    }
];

const actions = ({ handleQueryInput, getData }) => (
    <Grid container
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-end"
    >
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <IconButton aria-label="refresh" onClick={getData}>
                <SyncIcon />
            </IconButton>
        </Grid>
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <Search id="distributorSearch" handleChange={handleQueryInput} placeholder="Name..." />
        </Grid>
        <Grid item xs={12} sm={12} md={"auto"} lg={"auto"}>
            <Button disabled variant="contained" size="small" sx={{ float: 'right' }} endIcon={<FilterAltIcon />}>Filter</Button>
        </Grid>
    </Grid>
);

const contextActions = deleteHandler => (
    <IconButton onClick={deleteHandler}>
        <DeleteIcon />
    </IconButton>
);

const Create = ({ handleClickOpen }) => (
    <Button
        variant="contained"
        size="small"
        startIcon={<AddRoundedIcon />}
        onClick={handleClickOpen}
    >Create</Button>
)

function SearchQuery(queryString, data) {
    const query = queryString.toLowerCase();
    const filteredData = data.filter((d) => {
        const n = d.name.toLowerCase();
        return n.includes(query);
    }, data);
    return filteredData
}

export default function CompaniesPage() {
    dayjs.extend(localizedFormat);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [message, setMessage] = useState(null);
    const [data, setData] = useState([]);
    const [queryInput, setQueryInput] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);
    const [toggleCleared, setToggleCleared] = useState(false);
    // Avoid a layout jump when reaching the last page with empty rows.
    const deleteAll = () => {
        const rows = selectedRows.map(r => r.name);
        if (window.confirm(`Are you sure you want to delete?`)) {
            setToggleCleared(!toggleCleared);
            setData(_.differenceBy(data, rows, 'name'));
        }
    }
    const handleChange = (s) => {
        setSelectedRows(s.selectedRows);
    }
    const handleQueryInput = (e) => {
        const query = e.target.value.trim().toLowerCase();
        setQueryInput(query);
    }
    const handleClickOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }
    const handleSnakbarClose = () => setSnackbarOpen(false);
    // GET Request to get the list of companies
    const getData = async () => {
        setLoading(true);
        const response = await axios.get('/company');
        const result = await response.data;
        setData(result.data);
        if (!_.isEmpty(result)) setLoading(false);
    }
    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (!open && !_.isEmpty(message)) {
            getData();
        }
    }, [open]);

    return (
        <Box>
            {/* Sectoin Header */}
            <SectionHeader heading="Company" />
            <Box sx={{ flexGrow: 1, my: 2 }}>

                <DataTable
                    title={<Create handleClickOpen={handleClickOpen} />}
                    columns={columns}
                    data={SearchQuery(queryInput, data)}
                    defaultSortField="name"
                    defaultSortFieldId={1}
                    actions={actions({ handleQueryInput, getData })}
                    contextActions={contextActions(deleteAll)}
                    onSelectedRowsChange={handleChange}
                    clearSelectedRows={toggleCleared}
                    progressPending={loading}
                />
            </Box>
            <CreateCompany
                open={open}
                handleClose={handleClose}
                setSnackbarOpen={setSnackbarOpen}
                setMessage={setMessage}
                handleSnakbarClose={handleSnakbarClose}
            />
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={snackbarOpen}
                onClose={handleSnakbarClose}
                autoHideDuration={4000}
            >
                {
                    snackbarOpen ?
                        <Alert severity={(message['status']) ? "success" : "error"} onClose={handleSnakbarClose}>
                            {
                                message['message']
                            }
                        </Alert>
                        : null
                }
            </Snackbar>
        </Box>
    );
}