
// Mui
import { Box, Grid, Stack, Typography, CardContent, Divider } from "@mui/material";
// Icons
import { RiDualSim1Fill, RiDualSim2Fill } from "react-icons/ri"
import { BiSignal4 } from "react-icons/bi";

// Custom Components
import StyledCard from "../../components/Card/StyledCard.component";
import Item from "../../components/Item/Item.component";

const CellularCard = ({ profile }) => {
    return (
        <StyledCard>
            <CardContent>
                <Typography variant="h5" color='primary.dark' component={Box} gutterBottom>
                    Cellular Status
                </Typography>
                <Grid container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Grid item xs={12}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-around"
                            divider={<Divider orientation="vertical" flexItem />}
                        >
                            <Item>
                                <Stack
                                    direction="row"
                                    // divider={<Divider orientation="vertical" flexItem />}
                                    spacing={3}
                                >
                                    <Item>
                                        <RiDualSim1Fill size="2em" color="#E1E1E1" />
                                        <Typography variant="subtitle1" sx={{ color: '#707070' }}>SIM 1</Typography>
                                    </Item>
                                    <Item>
                                        <BiSignal4 size="2em" color="#E1E1E1" />
                                        <Typography variant="subtitle1" sx={{ color: '#707070' }}> -89 dBm</Typography>
                                    </Item>
                                    <Item>
                                        <Box>
                                            <Typography variant="subtitle1" sx={{ color: '#707070' }}>Operator</Typography>
                                            <Typography variant="body2" component={Box} sx={{ mt: '10px', color: '#808080' }}>Vi India</Typography>
                                        </Box>
                                    </Item>
                                </Stack>
                            </Item>
                            <Item>
                                <Stack
                                    direction="row"
                                    // divider={<Divider orientation="vertical" flexItem />}
                                    spacing={3}
                                >
                                    <Item>
                                        <RiDualSim2Fill size="2em" color="#E1E1E1" />
                                        <Typography variant="subtitle1" sx={{ color: '#707070' }}>SIM 2</Typography>
                                    </Item>
                                    <Item>
                                        <BiSignal4 size="2em" color="#E1E1E1" />
                                        <Typography variant="subtitle1" sx={{ color: '#707070' }}> -60 dBm</Typography>
                                    </Item>
                                    <Item>
                                        <Box>
                                            <Typography variant="subtitle1" sx={{ color: '#707070' }}>Operator</Typography>
                                            <Typography variant="body2" component={Box} sx={{ mt: '10px', color: '#808080' }}>Airtel</Typography>
                                        </Box>
                                    </Item>
                                </Stack>
                            </Item>
                        </Stack>
                    </Grid>
                </Grid>
            </CardContent>
        </StyledCard>
    );
}
export default CellularCard;