import react, { useState, useEffect } from "react";

// Utility
import _ from "lodash";
import dayjs from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

// Logo
import logo from '../../assets/IconWhite.png';

// Routing
import { Routes, Route, useNavigate } from "react-router-dom";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../services/login..service";

// Material UI Components
import { styled, useTheme } from '@mui/material/styles';

import {
    Box, Typography, Divider, IconButton, CssBaseline, Toolbar, Menu, MenuItem
} from "@mui/material";

import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';

// MUI Icons 
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemIcon from '@mui/material/ListItemIcon';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';

// Component
import AccountAvatar from "../../components/Nav/AccountAvatar.component";
import NavList from "../../components/Nav/Nav.component";
import Profile from "../Profile/Profile.page";
import OverviewPage from "../Overview/Overview.page";
import DevicesMain from "../Devices/DevicesMain.page";
import WebConnect from "../WebConnect/WebConnect.page";
import UsersPage from "../Users/Users.route";
import CompaniesPage from '../Companies/List.page';
import Roles from "../Roles/Roles";
// Drawer Properties
const drawerWidth = 200;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    justifyContent: 'center',
    padding: theme.spacing(0),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    // 
    backgroundImage: 'linear-gradient(to right,  #48A89D 40%, #134E5E 99%)'
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    header: {
        color: theme.palette.primary.dark
    },
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function MainDashboard() {
    dayjs.extend(localizedFormat);
    dayjs.extend(relativeTime);

    const dispatch = useDispatch();
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(true);

    const user = useSelector(state => state.authenticate.user);
    const navigate = useNavigate();

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleChange = (event) => {
        // setAuth(event.target.checked);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleProfile = () => {
        handleClose();
        navigate('profile');
    }

    const handleLogout = () => {
        handleClose();
        dispatch(logoutUser());
    }

    // React.useEffect(() => {
    //     dispatch(getAuthUserData());
    //     // console.log("USE EFFECT");
    // }, []);

    return (
        <Box sx={{ display: 'flex' }}>
            {/* <CssBaseline /> */}
            <AppBar position="fixed" open={open} style={{ backgroundColor: '#134E5E' }} >
                <Toolbar sx={{
                    backgroundColor: 'rgba(0,0,0,0.0)'
                }}>
                    {open ?

                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerClose}
                            sx={{
                                marginRight: 5,
                            }}
                        >
                            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton> : <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{
                                marginRight: 5,
                                // ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                    }
                    <Typography variant="h6" noWrap component="div">
                        {'Welcome to Atra'}
                    </Typography>
                    <Typography variant="subtitle2" noWrap component="div" sx={{ ml: 0.5, mt: 0.3 }}>{'(Alpha)'}</Typography>
                    <Box component="div" sx={{ flexGrow: 1 }}>
                        <Box sx={{ float: 'right' }} >
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >

                                <AccountAvatar user={user} />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', px: 2 }}>
                                        <Box sx={{ fontSize: '20px', fontWeight: 'bold', color: "#808080" }}>
                                            {
                                                _.isEmpty(user) ? "ZOMBIE USER" : user.idToken.payload.name
                                            }
                                        </Box>
                                        <Box sx={{ fontSize: '14px', color: "#A4A5A5" }}>
                                            {
                                                _.isEmpty(user) ? "zombie@zombieland.zom" : user.idToken.payload.email
                                            }
                                        </Box>
                                        {/* <Box sx={{ fontSize: '12px', color: "#A4A5A5" }}>
                                            {
                                                _.isEmpty(user) ? "x hours ago" : dayjs(user.lastLoginTime).fromNow()
                                            }
                                        </Box> */}
                                    </Box>
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={handleProfile}>
                                    <ListItemIcon>
                                        <AccountCircle />
                                    </ListItemIcon>
                                    Profile
                                </MenuItem>
                                <MenuItem onClick={handleLogout}>
                                    <ListItemIcon>
                                        <LogoutRoundedIcon fontSize="small" />
                                    </ListItemIcon>
                                    Logout
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <img src={logo} alt="Atra Logo" height={'48px'} />
                    {/* <Box sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',

                    }} */}


                    <Typography align="center" variant="h5" component="div" sx={{
                        color: '#f2f2f2', pt: 1, ml: 1
                    }}>
                        ATRA
                    </Typography>
                    {/* </Box> */}
                </DrawerHeader>
                <Divider />
                <NavList open={open} />
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 12 }}>
                {/* <DrawerHeader /> */}
                <Routes>
                    {/* Those are the BI Dashboards */}
                    <Route path="/" element={<OverviewPage />} />
                    <Route path="devices/*" element={<DevicesMain />} />
                    <Route path="profile" element={<Profile />} />
                    <Route path="remote" element={<WebConnect />} />
                    <Route path="users/*" element={<UsersPage />} />
                    <Route path="company" element={<CompaniesPage />} />
                    <Route path="/roles" element={<Roles />} />
                </Routes>
            </Box>
        </Box>
    );
}
