import { useState } from "react";
import _ from "lodash";

import {
    Box, Typography, Paper, Stack, Grid, Card, CardContent,
    CardHeader, IconButton, Divider, Chip, Avatar
} from "@mui/material";

// MUi Icons



// Custom Component
import DeviceMediaCard from "../../components/Devices/DeviceMediaCard2.component";
import GeneralCard from "./GeneralCard.component";
import ResourceCard from "./ResourceCard.component";
import WifiStatusCard from "./WifiCard.component";
import EthernetStatusCard from "./EthernetCard.component";
import CellularCard from "./CellularCard.component";

export default function Overview({ profile }) {
    const [isOnline, setisOnline] = useState((!_.isEmpty(profile)) ? profile.connectionStatus : false);
    return (
        <Box sx={{ width: '100%' }}>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={3} xl={3} >
                    {
                        (!_.isEmpty(profile)) ?
                            <DeviceMediaCard profile={profile} />
                            : null
                    }
                </Grid>
                <Grid item xs={12} lg={9} xl={9} >
                    {/* <Box sx={{ width: '100%', height: 'calc(100vh - 200px)', overflowY: 'scroll' }}> */}
                    {
                        (!_.isEmpty(profile)) ?
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={12} xl={12}>
                                    <GeneralCard profile={profile} />
                                </Grid>
                                {
                                    <Grid item xs={12} lg={12} xl={12}>
                                        <ResourceCard profile={profile} />
                                    </Grid>
                                }

                                {
                                    (_.has(profile, 'network.lan') && !_.isEmpty(profile.network.lan)) ?
                                        <Grid item xs={12} lg={12} xl={12}>
                                            <EthernetStatusCard profile={profile} />
                                        </Grid>
                                        : null
                                }
                                {
                                    (_.has(profile, 'network.wifi') && !_.isEmpty(profile.network.wifi)) ?
                                        <Grid item xs={12} lg={12} xl={12}>
                                            <WifiStatusCard profile={profile} />
                                        </Grid>
                                        : null
                                }
                                {
                                    (_.has(profile, 'network.cellular') && !_.isEmpty(profile.network.cellular)) ?
                                        <Grid item xs={12} lg={12} xl={12}>
                                            <CellularCard profile={profile} />
                                        </Grid>
                                        : null
                                }
                            </Grid>
                            : null
                    }
                    {/* </Box> */}
                </Grid>
            </Grid>
        </Box>
    );
}