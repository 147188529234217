import { useState } from "react";

// MUI Components
import { styled } from "@mui/material/styles";
import {
    Box, Button, Stack, TextField, Snackbar, Alert
} from "@mui/material";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
// Custom Components
import SectionHeader from "../../components/SectionHeader/SectionHeader.component";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const Item = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const validationSchema = Yup.object().shape({
    name: Yup.string('Must be string').required("Name is required"),
    serial: Yup.string('Must be string').required("Serial is required")
});

export default function AddDevices() {

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");

    const navigate = useNavigate();

    const handleClose = () => {
        setOpen(false);
        navigate('/devices', { replace: true });
    }
    const postData = async (data) => {
        const response = await axios.post('/devices/install', data);
        const result = await response.data;
        // console.log('Install response', response.status);
        setOpen(true);
        setMessage(result);
        if (result.status) {
            setTimeout(() => {
                navigate('/devices', { replace: true });
            }, 4000);
        }
    }
    const handleSubmit = (v) => {
        postData(v);
    }

    return (
        <Box>
            <SectionHeader heading="Add New Devices" />
            <Box sx={{ flexGrow: 1, my: 2 }}>
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                >
                    <Item>
                        <Formik
                            initialValues={{ name: '', serial: '' }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                                <Form>
                                    <Field
                                        as={TextField}
                                        // autoFocus
                                        fullWidth
                                        margin="normal"
                                        variant="standard"
                                        id="name"
                                        name="name"
                                        label="Name"
                                        value={values.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name && errors.name}
                                    />
                                    <Field
                                        as={TextField}

                                        fullWidth
                                        margin="normal"
                                        variant="standard"
                                        id="serial"
                                        name="serial"
                                        label="Serial"
                                        value={values.serial}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        error={touched.serial && Boolean(errors.serial)}
                                        helperText={touched.serial && errors.serial}
                                    />
                                    {/* <Button onClick={}>Cancel</Button> */}
                                    <Button variant="contained" type="submit">Add</Button>

                                </Form>
                            )}
                        </Formik>

                    </Item>
                    {
                        false ?
                            <Item>Item 2</Item> : null
                    }
                </Stack>
            </Box>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={open}
                onClose={handleClose}
                autoHideDuration={4000}
            >
                <Alert severity={(message.status) ? "success" : "error"} onClose={handleClose}>
                    {message['message']}
                </Alert>
            </Snackbar>
        </Box>
    );
}