import { Routes, Route } from "react-router-dom";

import DeviceProfile from "./DeviceProfile.page";
import DeviceList from "./DevicesList.page";
import AddDevices from "../Forms/AddDevices.page";
export default function DevicesMain() {
    return (
        <Routes>
            <Route path="/" element={<DeviceList />} />
            <Route path="/:serial" element={<DeviceProfile />} />
            <Route path='/add-devices' element={<AddDevices />} />
        </Routes>
    );
}

