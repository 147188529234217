// axios.interceptor.js
import axios from "axios";
import { userPool } from "../services/auth.service";
axios.defaults.baseURL = 'https://ntk4ooylm4.execute-api.ap-south-1.amazonaws.com/DeployAPI/';
// axios.defaults.baseURL = 'http://localhost:5001/';

const interceptor = (store) => {
    axios.interceptors.request.use(
        config => {
            const currentUser = userPool.getCurrentUser();
            // if (currentUser) {
            currentUser.getSession((err, sess) => {
                if (err) return Promise.reject(err);
                const token = sess['idToken']['jwtToken'];
                // if (token) 
                config.headers.Authorization = `${token}`;
            })
            // }
            return config;
        },
        error => Promise.reject(error)
    );

    axios.interceptors.response.use(
        response => response,
        error => {
            // if (error.response.status === 401) {
            // console.error('Interceptor Error', error);
            // }
            return Promise.reject(error);
        }
    );
}
// export default axios;
export default interceptor;