import {
    CognitoUserPool,
    CognitoUser,
    AuthenticationDetails,
    CognitoUserAttribute,
    CognitoUserSession
} from 'amazon-cognito-identity-js';
import { enc, HmacSHA256 } from 'crypto-js';

const userPool = new CognitoUserPool({
    UserPoolId: 'ap-south-1_8VP1VB04V',
    ClientId: '32up77rrqs0ac9om0evgsj0789',

});

// Login component
export const login = (username, password, onSuccess, onFailure) => {
    const authenticationDetails = new AuthenticationDetails({
        Username: username,
        Password: password,
    });

    const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
    });
    // const secretHash = generateSecretHash(username);
    cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess,
        onFailure,
        // SecretHash: secretHash,
    });
};

// Signup component
export const signup = (name, email, password, zoneinfo, onSuccess, onFailure) => {
    const attributeList = [
        new CognitoUserAttribute({ Name: 'name', Value: name }),
        new CognitoUserAttribute({ Name: 'email', Value: email }),
        new CognitoUserAttribute({ Name: 'zoneinfo', Value: zoneinfo }),
    ];

    const username = email;
    // const secretHash = generateSecretHash(username);
    const cognitoUser = userPool.signUp(
        username,
        password,
        attributeList,
        null,
        (err, result) => {
            if (err) {
                onFailure(err);
            } else {
                onSuccess(result);
            }
        },
        // {
        //     secretHash,
        // }
    );
};

// Verify email verification code
export const verifyEmail = (username, verificationCode, onSuccess, onFailure) => {
    const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
    });

    cognitoUser.confirmRegistration(verificationCode, true, (err, result) => {
        if (err) {
            onFailure(err);
        } else {
            onSuccess(result);
        }
    });
};

// Resend email verification code
export const resendVerificationCode = (username, onSuccess, onFailure) => {
    const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
    });

    cognitoUser.resendConfirmationCode((err, result) => {
        if (err) {
            onFailure(err);
        } else {
            onSuccess(result);
        }
    });
};


export const checkEmailVerification = (username, onSuccess, onFailure) => {
    const userData = {
        Username: username,
        Pool: userPool
    };
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.getUserAttributes((err, attributes) => {
        if (err) {
            onFailure(err);
        } else {
            const emailVerifiedAttribute = attributes.find(attribute => attribute.getName() == 'email_verified');
            if (emailVerifiedAttribute && emailVerifiedAttribute.getValue() === 'true') {
                onSuccess();
            } else {
                onFailure(new Error('Email not verified'));
            }
        }
    });
}



// Forgot password component
export const forgotPassword = (username, onSuccess, onFailure) => {
    const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
    });

    cognitoUser.forgotPassword({
        onSuccess,
        onFailure,
    });
};

// Reset password component
export const resetPassword = (username, verificationCode, newPassword, onSuccess, onFailure) => {
    const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
    });

    cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess,
        onFailure,
    });
};

// Logout component
export const logout = () => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
        cognitoUser.signOut();
    }
};

// Get date of authenticated user
export const getDateOfAuthenticatedUser = () => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
        const session = cognitoUser.getSignInUserSession();
        if (session) {
            const idToken = session.getIdToken();
            if (idToken) {
                return idToken.payload.date;
            }
        }
    }
};

export { userPool, CognitoUser, CognitoUserSession };




