import { createReducer } from "@reduxjs/toolkit";
// Apex Chart Option pre-configured
import AreaChartOptions from "../../layouts/Charts/AreaChart.config";
// Actions
import {
    onRequest, onSuccessResponse, onEmptyResponse, onError
} from '../actions/metric.action';

// Constants
const noData = {
    text: "No Data present in the graph!",
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
        color: "#000000",
        fontSize: '14px',
        fontFamily: "Helvetica"
    }
}

// Initial State
const INITIAL_STATE = {
    loading: false,
    cpu: {
        options: {
            ...AreaChartOptions,
            colors: ['#F4B400', '#F2711C']
        },
        series: []
    },
    ram: {
        options: {
            ...AreaChartOptions,
            colors: ['#F2711C']
        },
        series: []
    },
    storage: {
        options: {
            ...AreaChartOptions,
            colors: ['#B388FF']
        },
        series: []
    },
    temperature: {
        options: {
            ...AreaChartOptions,
            colors: ['#038C7F']
        },
        series: []
    },
    error: null
}

const metricReducer = createReducer(INITIAL_STATE, (builder) => {
    builder.
        addCase(onRequest, (state) => {
            state.loading = true;
            state.cpu.options = {
                ...state.cpu.options,
                'noData': {
                    ...noData,
                    text: 'Loading...'
                }
            };
            state.ram.options = {
                ...state.ram.options,
                'noData': {
                    ...noData,
                    text: 'Loading...'
                }
            };
            state.storage.options = {
                ...state.storage.options,
                'noData': {
                    ...noData,
                    text: 'Loading...'
                }
            };
            state.temperature.options = {
                ...state.temperature.options,
                'noData': {
                    ...noData,
                    text: 'Loading...'
                }
            }
        })
        .addCase(onEmptyResponse, (state, action) => {
            state.loading = false;
            state.cpu.options = {
                ...state.cpu.options,
                noData
            };
            state.cpu.series = action.payload.cpu;
            state.ram.options = {
                ...state.ram.options,
                noData
            };
            state.ram.series = action.payload.ram;

            state.storage.options = {
                ...state.storage.options,
                noData
            };
            state.storage.series = action.payload.storage;

            state.temperature.options = {
                ...state.temperature.options,
                noData
            }
            state.temperature.series = action.payload.temperature;
        })
        .addCase(onSuccessResponse, (state, action) => {
            state.loading = false;
            state.cpu.series = action.payload.cpu;
            state.ram.series = action.payload.ram;
            state.storage.series = action.payload.storage;
            state.temperature.series = action.payload.temperature;
        })
        .addCase(onError, (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.cpu.series = []
            state.ram.series = []
            state.storage.series = []
            state.temperature.series = []
        });
});

export default metricReducer;