const indianOcean = {
    palette: {
        primary: {
            light: '#ABD9E6',
            // main: '#48A89D',
            dark: '#134E5E',
            // contrastText: '#E1E1E1'
            main: '#48A89D',
            // mainGradient: "linear-gradient(to bottom right, #ABD9E6 0%, #48A89D 27%, #134E5E 93%)",
            mainGradient: "linear-gradient(to right, #ABD9E6 0%, #48A89D 27%, #134E5E 93%)",
            contrastText: '#f2f2f2'
        },
        secondary: {
            light: '#B5CC18',
            main: '#FBBD08',
            dark: '#F2711C',
            contrastText: '#E1E1E1'
        },
        // error: {
        //     light: '#ABD9E6',
        //     main: '#48A89D',
        //     dark: '#134E5E',
        //     contrastText: '#E1E1E1'
        // },
        // warning: {
        //     light: '#ABD9E6',
        //     main: '#48A89D',
        //     dark: '#134E5E',
        //     contrastText: '#E1E1E1'
        // },
        // info: {
        //     light: '#ABD9E6',
        //     main: '#48A89D',
        //     dark: '#134E5E',
        //     contrastText: '#E1E1E1'
        // },
        // success: {
        //     light: '#ABD9E6',
        //     main: '#48A89D',
        //     dark: '#134E5E',
        //     contrastText: '#E1E1E1'
        // }

    },
    spacing: 6,
    typography: {
        // In Chinese and Japanese the characters are usually larger,
        // so a smaller fontsize may be appropriate.
        fontFamily: [
            // '-apple-system',
            // 'BlinkMacSystemFont',
            // '"Segoe UI"',
            // 'Roboto',
            'Hind',
            // '"Public Sans"',
            // '"Helvetica Neue"',
            // 'Arial',
            // 'sans-serif',
            // '"Apple Color Emoji"',
            // '"Segoe UI Emoji"',
            // '"Segoe UI Symbol"',
        ].join(','),
        fontSize: 14,
    },
    overrides: {
        //   MuiTablePagination: {
        //     spacer: {
        //       flex: 'none'
        //     }
        //   },
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: "#662E9B",

            },
        },
    },
    components: {
        MuiListItem: {
            styleOverrides: {
                root: {
                    // backgroundColor: 'blue',

                    '&.Mui-selected': {
                        backgroundColor: "#48A89D",
                        // backgroundImage:
                        //     // 'linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.0))',
                        //     'linear-gradient(to right, rgba(72, 168, 157, 0.8), rgba(19, 78, 94, 0.8) )',
                        color: '#f2f2f2',

                    },
                    '&.Mui-selected:hover': {
                        backgroundColor: "#48A89D",
                        // backgroundImage:
                        //     // 'linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.0))',
                        //     'linear-gradient(to right, rgba(72, 168, 157, 0.8), rgba(19, 78, 94, 0.8) )',
                        color: '#f2f2f2',

                    },
                },
            },
        },
    },
};

export default indianOcean;