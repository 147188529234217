import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, Link, Card, Chip, Grid, Avatar, Typography, CardContent, Divider } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import _ from 'lodash';

// Item is custom component
const Item = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const StyledCardMedia = styled('div')({
    position: 'relative',
    paddingTop: 'calc(100% * 3 / 4)',
    // backgroundColor: '#48a89d',
    // backgroundImage: 'linear-gradient(to bottom left, #ABD9E6 0%, #48A89D 27%, #134E5E 93%)',
    // '&::before': {
    //     top: 0,
    //     left: 0,
    //     width: '100%',
    //     height: '100%',
    //     backgroundImage: 'linear-gradient(to right, rgba(19, 78, 94, 0.88)  27%, rgba(72, 168, 157, 0.7) 83%, rgba(171, 217, 230, 0.8) 10%)',
    //     // opacity: 1,
    // }

    // borderBottom: '1px solid #ECEFF1'
})
const StyledTitle = styled(Link)({
    height: 44,
    overflow: 'hidden',
    WebkitLineClamp: 2,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
});

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    zIndex: 9,
    width: 32,
    height: 32,
    position: 'absolute',
    left: theme.spacing(3),
    bottom: theme.spacing(-2),
}));

const StyledInfo = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    // color: theme.palette.text.disabled,
    color: 'inherit'
}));

const StyledCover = styled('img')({
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',

});

const DeviceMediaCard = ({ profile }) => {
    dayjs.extend(relativeTime);
    const title = profile.model;
    const cover = profile.modelImageURI;
    return (
        <Box>
            <Card sx={{
                position: 'relative',
                backgroundImage: 'linear-gradient(to bottom left, #ABD9E6 0%, #48A89D 27%, #134E5E 93%)',
                color: 'white'
            }} elevation={3}>
                <StyledCardMedia>
                    <StyledCover alt={title} src={cover} />
                </StyledCardMedia>
                <CardContent sx={{ pt: 4, backgroundColor: 'rgba(255,255,255,0.2)' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Box>
                                <Typography variant="subtitle1" >Serial</Typography>
                                <Typography variant="caption" component={Box} >{profile.serial}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box>
                                <Typography variant="subtitle1" >Connection Status</Typography>
                                {
                                    profile.connectionStatus ? (
                                        <Box sx={{ display: "flex", alignItems: "center", my: '0.5em' }}>
                                            <Chip label="Online" color="success" size="small" icon={<CheckCircleIcon />} />
                                        </Box>
                                    ) : (
                                        <Box sx={{ display: "flex", alignItems: "center", my: '0.5em' }}>
                                            <Chip label="Offline" color="error" size="small" icon={<ErrorIcon />} />
                                        </Box>
                                    )
                                }
                                <Typography variant="caption" sx={{ color: 'inherit', display: 'block' }}>
                                    {`last updated ${dayjs(profile.lastUpdated).fromNow()}`}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box>
                                <Typography variant="subtitle1" >Model</Typography>
                                <Typography
                                    color="inherit"
                                    variant="caption"
                                >
                                    {title}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    );
}
export default DeviceMediaCard;