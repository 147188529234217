import { useState } from "react";
import { useNavigate } from "react-router-dom";

// Material Icons

import {
    List, Divider
} from "@mui/material";

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled, useTheme } from "@mui/material/styles"

import { DashoardItems, ManageItems } from "./NavItems.component";


const StyledListItem = styled(ListItem)(({ theme, selected, open }) => ({
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: theme.spacing(2.5),
    // backgroundColor: selected ? theme.palette.action.selected : 'inherit',
}));


export default function Navlist(props) {
    const [selectedIndex, setSelectedIndex] = useState('overview');
    const navigate = useNavigate();

    const handleListItemClick = (event, index, path) => {
        navigate(path);
        setSelectedIndex(index);
    }
    return (
        <>
            <List>
                {
                    DashoardItems.map((text, index) => (

                        <StyledListItem
                            key={text.id}
                            // button
                            disablePadding
                        // onClick={(event) => handleListItemClick(event, text.id, text.path)}
                        // selected={selectedIndex === text.id}
                        >
                            <ListItemButton
                                onClick={(event) => handleListItemClick(event, text.id, text.path)}
                                selected={selectedIndex === text.id}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: props.open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: props.open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {text.icon}
                                </ListItemIcon>
                                <ListItemText primary={text.title} sx={{ opacity: props.open ? 1 : 0 }}
                                    primaryTypographyProps={{
                                        // variant: 'subtitle2',
                                        style: {
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }
                                    }}
                                />
                            </ListItemButton>
                        </StyledListItem>
                        // </ListItem>
                    ))
                }
            </List >
            <Divider />
            <List>
                {
                    ManageItems.map((text, index) => (
                        <StyledListItem
                            key={text.id}
                            // disablePadding sx={{ display: 'block' }}
                            // onClick={() => navigate(text.path)}
                            disablePadding
                        >
                            <ListItemButton
                                onClick={(event) => handleListItemClick(event, text.id, text.path)}
                                selected={selectedIndex === text.id}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: props.open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: props.open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {text.icon}
                                </ListItemIcon>
                                <ListItemText primary={text.title} sx={{ opacity: props.open ? 1 : 0 }}
                                    primaryTypographyProps={{
                                        // variant: 'subtitle2',
                                        style: {
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }
                                    }}
                                />
                            </ListItemButton>
                        </StyledListItem>
                    ))
                }
            </List >
        </>
    );
}