import {
    Box, Dialog, DialogActions, DialogContent, DialogContentText, FormHelperText, Divider,
    DialogTitle, FormControl, Button, InputLabel, MenuItem, Select, TextField
} from "@mui/material";
import axios from "axios";
// import { map } from "lodash";
import { useEffect, useState } from "react";
// import { useFormik } from "formik";
export default function CreateCompany({ open,
    handleClose, setSnackbarOpen,
    setMessage,
    handleSnakbarClose }) {
    const [companyName, setCompanyName] = useState('')
    const [parentCompany, setParentCompany] = useState(null);
    const [parentCompanyList, setParentCompanyList] = useState([]);
    const [selectError, setSelectError] = useState('');
    const [nameError, setNameError] = useState('');
    const handleSelect = (event) => {
        // console.log("The parentCompany Value", event.target.value);
        setParentCompany(event.target.value)
    }
    const getData = async () => {
        const response = await axios.get('/company/list2');
        const result = await response.data;
        setParentCompanyList(result.data);
    }
    const postData = async (data) => {
        const response = await axios.post('/company', data);
        const result = await response.data;
        handleSnakbarClose();
        setSnackbarOpen(true);
        setMessage(result);
        if (result.status) {
            handleClose();
            return;
        }
        return;
    }
    const handleChange = (event) => {
        setNameError('');
        setCompanyName(event.target.value);
    }
    // const formik = useFormik({
    //     initialValues: {
    //         deviceId: parentCompany
    //     },
    //     validationSchema: null,
    //     onSubmit: (v) => {
    //         alert(v);
    //     }
    // })
    const handleSubmit = (event) => {
        event.preventDefault();
        const regex = /^[a-zA-Z ]{2,30}$/;
        // Handle form submission logic here
        if (companyName === null || companyName === undefined || companyName == "") {
            setNameError('Name required');
            return;
        }
        if (!regex.test(companyName)) {
            setNameError('Not valid');
            return;
        }
        if (parentCompany === null || parentCompany === undefined || parentCompany == "") {
            setSelectError('Please select parent company');
            return;
        }
        setNameError('');
        setSelectError('');

        const formData = {
            parentCompanyId: parentCompany,
            name: companyName
        }
        alert(JSON.stringify(formData));
        postData(formData);
    };
    useEffect(() => {
        if (open) {
            setCompanyName('');
            setSelectError('');
            getData();
        }
    }, [open]);

    return (

        <Box>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={open}
                onClose={handleClose}
            >
                <form onSubmit={handleSubmit}>
                    <DialogTitle>Create New Company</DialogTitle>
                    <DialogContent>
                        {/* <DialogContentText>
                            Create a tunnel to configure the device remotely. Currently only 'http' type tunnel is supported, team of Atreyo is working on additional features to be implemented soon.
                        </DialogContentText> */}
                        <Box
                            noValidate
                            component="form"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                m: 'auto',
                                // width: 'fit-content',
                            }}
                        >
                            <Box sx={{ mt: 2, minWidth: 350 }}>
                                <TextField
                                    size="small"
                                    name="companyName"
                                    id="company-name"
                                    label="Name"
                                    variant="outlined"
                                    value={companyName}
                                    fullWidth
                                    required
                                    onChange={handleChange}
                                    error={(nameError !== "") ? true : false}
                                    helperText={(nameError !== "") ? nameError : null}
                                />
                            </Box>
                            <FormControl sx={{ mt: 2, minWidth: 350 }} size="small">

                                <InputLabel id="select-company">Select Parent Company</InputLabel>
                                <Select
                                    autoFocus
                                    name="company-selector"
                                    labelId="select-company"
                                    id="company-selector"
                                    value={parentCompany}
                                    onChange={handleSelect}
                                    // autoWidth
                                    label="Select Parent Company"
                                    inputProps={{
                                        name: 'max-width',
                                        id: 'max-width',
                                    }}
                                >
                                    <MenuItem value="current">Current Company</MenuItem>
                                    <Divider />
                                    {
                                        parentCompanyList.map(d => (

                                            <MenuItem key={d._docId} value={d._docId}>{d.name}</MenuItem>
                                        ))
                                    }
                                </Select>
                                {(selectError !== "") ? <FormHelperText>{selectError}</FormHelperText> : null}
                            </FormControl>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleSubmit}>Add</Button>
                        <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Box>
    );
}




