import { useNavigate } from 'react-router-dom';
// @mui
import { styled } from "@mui/material/styles";
import {
    Box, Typography, Grid, Stack, Paper, Avatar, Button
} from '@mui/material';
// Custom Components
import { BoxItem } from "../Common/Item.component";
import CreativeIcon1 from '../../assets/Creative team-bro-amber.svg';
const Item = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    // textAlign: 'center',
    color: theme.palette.text.secondary,
}));
const GradientTypography = styled(Typography)(({ theme }) => ({
    // background: `-webkit-linear-gradient(-65deg, #ABD9E6 5%, #48A89D 27%, #134E5E 95%)`,
    background: `-webkit-linear-gradient(#48A89D 27%, #134E5E 95%)`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    // paddingBottom: theme.spacing(6),
}));
const WelcomeCard = () => {
    const navigate = useNavigate();
    return (
        <Paper elevation={4}>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={0}
            >
                <Grid item md={6}>
                    <Box sx={{ p: 5, maxHeight: "300px" }}>
                        <Stack
                            direction="column"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={2}
                        >
                            <Item>
                                <GradientTypography variant="h5">Welcome to Atra</GradientTypography>
                            </Item>
                            <Item>
                                <Typography variant="body1" sx={{ color: '#707070' }}>A device and remote management platform, get started by adding device to platform.</Typography>
                            </Item>
                            <Item>
                                <Button
                                    variant='contained'
                                    color='warning'
                                    size='small'
                                    onClick={() => navigate('/devices/add-devices', { replace: true })}
                                >Add Device</Button>
                            </Item>
                        </Stack>
                    </Box>
                </Grid>
                <Grid item md={6}>
                    <Avatar
                        variant="rounded"
                        src={CreativeIcon1}
                        sx={{
                            width: '100%',
                            height: 'auto',
                            maxHeight: '280px',
                            // objectFit: 'cover' 
                            // position: 'absolute',
                        }}
                    />
                </Grid>
            </Grid>
        </Paper>
    )
};
export default WelcomeCard;