import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

//  Card styled component
const StyledCard = styled(Box)({
    minWidth: 275,
    borderRadius: '10px',
    // boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
});
export default StyledCard;
