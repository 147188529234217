import { createReducer } from "@reduxjs/toolkit";
import {
    signupRequest,
    signupSuccess,
    signupFailure,
    signupReset,
    verifyCodeRequest,
    verifyCodeSuccess,
    verifyCodeFailure
} from '../actions/signup.action';

const INITIAL_STATE = {
    loading: false,
    user: null,
    error: null,
    verificationDone: false,
};

const signUpReducer = createReducer(INITIAL_STATE, (builder) => {
    builder.addCase(signupRequest, (state) => {
        state.loading = true;
        state.error = null;
    })
        .addCase(signupSuccess, (state, action) => {
            state.loading = false;
            state.user = action.payload;
            state.error = null;
        })
        .addCase(signupFailure, (state, action) => {
            state.loading = false;
            state.user = null;
            state.error = action.payload;
        })
        .addCase(verifyCodeRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(verifyCodeSuccess, (state) => {
            state.loading = false;
            state.error = null;
            state.verificationDone = true;
            state.user = null;

        })
        .addCase(verifyCodeFailure, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(signupReset, (state)=>{
            state.loading= false;
            state.user= null;
            state.error= null;
            state.verificationDone= false;
        })
});

export default signUpReducer;