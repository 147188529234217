// @mui
import { Box, Typography } from '@mui/material';

const FieldCard = ({ fieldTitle, fieldValue }) => {
    return (
        <Box>
            <Typography
                variant="subtitle1"
                sx={{ color: '#707070' }}>
                {fieldTitle}
            </Typography>
            {/* <Divider /> */}
            <Typography
                variant="body2"
                component={Box}
                sx={{ mt: '10px', color: '#808080' }}
            >
                {fieldValue}
            </Typography>
        </Box>
    );
};

export default FieldCard;