import react from "react";

// Material Icons
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';
import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import WebIcon from '@mui/icons-material/Web';
// import StyleIcon from '@mui/icons-material/Style';
import { RiRouterFill } from 'react-icons/ri';
import { FaUserLock } from 'react-icons/fa';

const DashoardItems = [
    {
        "id": "overview",
        "title": "Overview",
        icon: <HomeRoundedIcon />,
        "path": "/",
    },
    {
        "id": "devices",
        "title": "Devices",
        icon: <RiRouterFill size='24px' />,
        "path": "/devices",
    },
    {
        "id": "remote",
        "title": "Remote",
        icon: <WebIcon />,
        "path": "/remote",
    },
    // {
    //     "id": "tags",
    //     "title": "Tags",
    //     icon: <StyleIcon />,
    //     "path": "/tags",
    // },

];

const ManageItems = [
    {
        "id": "company",
        "title": "Company",
        icon: <BusinessIcon />,
        "path": "/company",
    },
    {
        "id": "user",
        "title": "Users",
        icon: <PersonIcon />,
        "path": "/users",
    },
    {
        "id": "roles",
        "title": "Roles",
        icon: <FaUserLock size='24px' />,
        "path": "/roles",
    },

];

export { DashoardItems, ManageItems };