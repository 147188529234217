import { createReducer } from "@reduxjs/toolkit";
import { userPool } from "../../services/auth.service";
import {
    loginRequest, loginSuccess, loginFailure, logoutRequest
} from '../actions/login.action';
import { setTimeZone } from '../../helper/Utility.helper';

const INITIAL_STATE = {
    loading: false,
    user: null,
    error: null,
    isAuthenticated: false
};

const currentUser = userPool.getCurrentUser();
if (currentUser) {
    currentUser.getSession((error, session) => {
        if (error) {
            localStorage.clear();
            INITIAL_STATE.error = error;
        } else {
            INITIAL_STATE.isAuthenticated = true;
            INITIAL_STATE.user = session;
            setTimeZone(session['idToken']['payload']['zoneinfo']);
        }
    });
}

const loginReducer = createReducer(INITIAL_STATE, (builder) => {
    builder
        .addCase(loginRequest, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(loginSuccess, (state, action) => {
            state.loading = false;
            state.error = null;
            state.user = action.payload;
            state.isAuthenticated = true;
        })
        .addCase(loginFailure, (state, action) => {
            state.loading = false;
            state.user = null;
            state.isAuthenticated = false;
            state.error = action.payload;
        })
        .addCase(logoutRequest, (state) => {
            // localStorage.clear();
            state.loading = false;
            state.user = null;
            state.isAuthenticated = false;
            state.error = null;
        })
});

export default loginReducer;