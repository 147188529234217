import _ from 'lodash';
// Mui
import { Box, Typography, CardContent, LinearProgress } from "@mui/material";

// Custom Components
import StyledCard from "../../components/Card/StyledCard.component";

const ResourceCard = ({ profile }) => {
    const cpu = (_.has(profile, 'utilization.cpu')) ? profile.utilization.cpu : '0';
    const ram = (_.has(profile, 'utilization.ram')) ? profile.utilization.ram : '0';
    // const flash = 0;
    const internalStorage = (_.has(profile, 'utilization.storage')) ? profile.utilization.storage : '0';

    return (
        <StyledCard>
            <CardContent>
                <Typography variant="h5" color='primary.dark' gutterBottom>
                    Resource Utilization
                </Typography>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="subtitle1" sx={{ color: '#707070' }}>
                        CPU:
                    </Typography>
                    <Typography variant="subtitle1" sx={{ mt: '10px', color: '#808080' }}>
                        {`${cpu} %`}
                    </Typography>
                </Box>
                <LinearProgress variant="determinate" value={cpu} />

                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="subtitle1" sx={{ color: '#707070' }}>
                        RAM:
                    </Typography>
                    <Typography variant="subtitle1" sx={{ mt: '10px', color: '#808080' }}>
                        {`${ram} %`}
                    </Typography>
                </Box>
                <LinearProgress variant="determinate" value={ram} />
                {/* <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="subtitle1" sx={{ color: '#707070' }}>
                        Flash:
                    </Typography>
                    <Typography variant="subtitle1" sx={{ mt: '10px', color: '#808080' }}>
                        {`${flash} %`}
                    </Typography>
                </Box>
                <LinearProgress variant="determinate" value={flash} /> */}
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="subtitle1" sx={{ color: '#707070' }}>
                        Internal Storage:
                    </Typography>
                    <Typography variant="subtitle1" sx={{ mt: '10px', color: '#808080' }}>
                        {`${internalStorage} %`}
                    </Typography>
                </Box>
                <LinearProgress variant="determinate" value={internalStorage} />
            </CardContent>
        </StyledCard>
    );
}
export default ResourceCard;