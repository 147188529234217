// Routing
import { Routes, Route } from "react-router-dom";

// Other Components
import RoleList from "./RoleList.page";
// import CreateRole from "../Forms/CreateRole.component";

export default function Roles() {
    return (
        <Routes>
            <Route path="/" element={<RoleList />} />
            {/* <Route path="/createRole" element={<CreateRole />} /> */}
            {/* <Route path="/:id" element={<h5>User Profile Section</h5>} /> */}
        </Routes>
    )
}