import {
    loginRequest, loginSuccess, loginFailure, logoutRequest
} from '../redux/actions/login.action';
import { login, logout } from './auth.service';

export const loginUser = (data) => async (dispatch) => {
    dispatch(loginRequest());
    await login(data['email'], data['password'],
        (response) => {
            // console.log("Login Success response", response);
            dispatch(loginSuccess(response))
        },
        (error) => {
            // console.log("Login Error", error);
            dispatch(loginFailure(error));
        });
}

export const logoutUser = () => async (dispatch) => {
    logout();
    dispatch(logoutRequest());
}

