import numbro from 'numbro';
import _ from 'lodash';
// Mui
import { Box, Paper, Typography, Grid, CardContent } from "@mui/material";
import { BsEthernet } from 'react-icons/bs';
// Custom Components
import StyledCard from "../../components/Card/StyledCard.component";
// utility
import { getConvertedSpeed } from '../../helper/Utility.helper';

const EthernetStatusCard = ({ profile }) => {
    const ethernet = (_.has(profile, 'network.lan')) ? profile.network.lan : [];

    return (
        <StyledCard>
            <CardContent>
                <Typography variant="h5" color='primary.dark' component={Box} gutterBottom>
                    Ethernet Status
                </Typography>
                <Box display="flex" flexDirection="column">
                    {
                        (!_.isEmpty(ethernet)) ? ethernet.map((obj, index) => (

                            <Grid
                                component={Paper}
                                container
                                key={obj.slot}
                                spacing={1}
                                sx={
                                    (index === 0 && ethernet.length > 1) ? {
                                        p: 2, py: 4, mb: 3
                                    } : {
                                        p: 2, py: 4
                                    }
                                }
                            >
                                <Grid item xs={3}>
                                    <Box>
                                        <Typography variant="subtitle1" sx={{ color: '#707070' }}>{_.upperCase(obj.slot)}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={9}>
                                    {/* <BsEthernet /> */}
                                    <Box sx={{
                                        backgroundColor: '#28B25F',
                                        // height: '1.5em',
                                        border: '1px solid #E1E1E1',
                                        borderRadius: '2.5px',
                                        textAlign: 'right',
                                        pr: 2,
                                        color: '#FFF',
                                        fontSize: 14
                                    }}
                                    >
                                        {getConvertedSpeed(obj.speed)}
                                    </Box>
                                </Grid>
                                <Grid item xs={3}>
                                    <Box>
                                        <Typography variant="subtitle1" sx={{ color: '#707070' }}>Interface Name</Typography>
                                        <Typography variant="body2" component={Box} sx={{ mt: '10px', color: '#808080' }}>{obj.interfaceName}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={3}>
                                    <Box>
                                        <Typography variant="subtitle1" sx={{ color: '#707070' }}>Mac</Typography>
                                        <Typography variant="body2" component={Box} sx={{ mt: '10px', color: '#808080' }}>{obj.mac.replaceAll(':', '-').toUpperCase()}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={3}>
                                    <Box>
                                        <Typography variant="subtitle1" sx={{ color: '#707070' }}>IPv4</Typography>
                                        <Typography variant="body2" component={Box} sx={{ mt: '10px', color: '#808080' }}>{obj.ipv4}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={3}>
                                    <Box>
                                        <Typography variant="subtitle1" sx={{ color: '#707070' }}>Download Speed</Typography>
                                        <Typography variant="body2" component={Box} sx={{ mt: '10px', color: '#808080' }}>{getConvertedSpeed(obj.speed)}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={3}>
                                    <Box>
                                        <Typography variant="subtitle1" sx={{ color: '#707070' }}>Upload Speed</Typography>
                                        <Typography variant="body2" component={Box} sx={{ mt: '10px', color: '#808080' }}>{getConvertedSpeed(obj.uploadSpeed)}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={3}>
                                    <Box>
                                        <Typography variant="subtitle1" sx={{ color: '#707070' }}>Downloaded Data</Typography>
                                        <Typography variant="body2" component={Box} sx={{ mt: '10px', color: '#808080' }}>{obj.downloadBytes}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={3}>
                                    <Box>
                                        <Typography variant="subtitle1" sx={{ color: '#707070' }}>Upload Data</Typography>
                                        <Typography variant="body2" component={Box} sx={{ mt: '10px', color: '#808080' }}>{obj.uploadBytes}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        )) : 'no data found'
                    }
                </Box>

                {/* <Grid item xs={3}>
                    <Box>
                        <Typography variant="subtitle1" sx={{ color: '#707070' }}>LAN 2</Typography>
                    </Box>
                </Grid>
                <Grid item xs={9}>
                    <Box sx={{ backgroundColor: '#28B25F', height: '1.5em', border: '1px solid #E1E1E1', borderRadius: '2.5px', textAlign: 'right', pr: 2, color: '#FFF' }} >
                        {`2.4 MB/s`}
                        </Box>
                        <Box  sx={{ height: '1.5em', border: '1px solid #E1E1E1', borderRadius: '2.5px' }} /> 
                </Grid> */}
            </CardContent>
        </StyledCard>
    );
}
export default EthernetStatusCard;