import _ from 'lodash';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker, Popup, Tooltip } from 'react-leaflet';

// @mui
import { Box, Typography, Grid } from '@mui/material';

// Icons
import ConnectedDeviceIcon from '../../assets/Signals.gif';
import DisconnectedDeviceIcon from '../../assets/Caution.png';

// Custom Components
// import StyledCard from "../Card/StyledCard.component";
import StyledCard from '../../components/Card/StyledCard.component';
import FieldCard from "../../components/Card/FieldCard.components";

// Example custom icon
const customConnectedIcon = new L.Icon({
    iconUrl: ConnectedDeviceIcon,
    iconSize: [30, 40.5], // size of the icon
    iconAnchor: [16, 32], // point of the icon which will correspond to marker's location
    popupAnchor: [0, -32], // point from which the popup should open relative to the iconAnchor
});

const customDisconnectedIcon = new L.Icon({
    iconUrl: DisconnectedDeviceIcon,
    iconSize: [30, 40.5], // size of the icon
    iconAnchor: [16, 32], // point of the icon which will correspond to marker's location
    popupAnchor: [0, -32], // point from which the popup should open relative to the iconAnchor
});

export default function DeviceLocation({ data }) {
    const l = data.location;
    const lat = parseFloat(l.mapCoords[0]);
    const long = parseFloat(l.mapCoords[1]);
    return (
        <Box width={'100%'}>
            <Box>
                <StyledCard>
                    <Typography variant="h5" color='primary.dark' gutterBottom>
                        Location Information
                    </Typography>
                    <Grid container spacing={2} >
                        <Grid item xs={4}>
                            <FieldCard
                                fieldTitle={'Coordinates'}
                                fieldValue={_.join(l.coords, ', ')}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FieldCard
                                fieldTitle={'Direction'}
                                fieldValue={_.join(l.direction, ', ')}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FieldCard
                                fieldTitle={'Altitude'}
                                fieldValue={l.altitude}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FieldCard
                                fieldTitle={'Speed'}
                                fieldValue={l.speed}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FieldCard
                                fieldTitle={'Fix'}
                                fieldValue={l.fix}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FieldCard
                                fieldTitle={'Course'}
                                fieldValue={l.course}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FieldCard
                                fieldTitle={'Satellites'}
                                fieldValue={l.satellites}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FieldCard
                                fieldTitle={'Accuracy'}
                                fieldValue={l.accuracy}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FieldCard
                                fieldTitle={'Validity'}
                                fieldValue={l.validity}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FieldCard
                                fieldTitle={'GPS Time'}
                                fieldValue={l.gpsTime}
                            />
                        </Grid>
                    </Grid>
                </StyledCard>
            </Box>
            {/* <Box marginY={3}>
                <Divider />
            </Box> */}
            <Box marginTop={3}>
                <MapContainer center={[lat, long]} zoom={17} style={{ height: '400px' }}>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='Atreyo R And D LLp'
                    />
                    <Marker position={[lat, long]} icon={
                        (data.connectionStatus) ? customConnectedIcon : customDisconnectedIcon
                    }>
                        {/* <Popup>
                    <PopUpDialog />
                </Popup> */}
                        <Tooltip direction='top' offset={[-1, -30]}>
                            {(data.connectionStatus) ? 'Online' : 'Offline'}
                        </Tooltip>
                    </Marker>
                </MapContainer>
            </Box>
        </Box>
    );
}