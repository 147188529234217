import { combineReducers } from "redux";

import signUpReducer from './reducers/registration.reducer';
import loginReducer from './reducers/login.reducer';
import metricReducer from "./reducers/metric.reducer";
const reducers = combineReducers({
    authenticate: loginReducer,
    signup: signUpReducer,
    metricData: metricReducer,
});

export default reducers;