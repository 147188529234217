import { useNavigate } from "react-router-dom";
import { signup, verifyEmail } from "./auth.service";
import { getDefaultTimezone } from "../helper/Utility.helper";
import {
    signupRequest,
    signupSuccess,
    signupFailure,
    verifyCodeRequest,
    verifyCodeSuccess,
    verifyCodeFailure,
    signupReset
} from '../redux/actions/signup.action';
import axios from "axios";

export const signUpUser = (data) => async (dispatch) => {
    dispatch(signupRequest());
    // try {
    await signup(data['name'], data['email'], data['password'], getDefaultTimezone(), (user) => {
        // Signup success
        // console.log('User registered successfully:', user);
        dispatch(signupSuccess(user));
        // const response = axios.post();
        // const result = result.data;
        // console.log(result);

    }, (err) => {
        // Signup failure
        console.error('Error registering user:', err);
        dispatch(signupFailure(err));
    });
    // } catch (error) {
    //     signupFailure(error);
    // }
}

export const verifyUserEmail = (data) => async (dispatch) => {
    dispatch(verifyCodeRequest());
    verifyEmail(data['email'], data['code'],
        (response) => {
            // console.log("Verification Response", response);
            dispatch(verifyCodeSuccess());
        }, (err) => {
            console.log("Verification Error", err);
            dispatch(verifyCodeFailure(err));
        });
}

export const resetSignup = signupReset;

